<template>
  <div>
    <Modal
      :cardTitle="false"
      :withClose="withClose"
      myMaxWidth="900px"
      :modalFooter="false"
      v-model="show"
    >
      <v-container fluid pa-0 mt-4 class="pix">
        <v-layout justify-center>
          <h4 class="fs-20 pr-2">Pague com</h4>
          <img src="/images/pix.svg" alt="" />
        </v-layout>
        <p class="fs-20 fw-300 text-center">é muito rápido e seguro!</p>
        <!-- <v-layout justify-center class="group-buttons">
          <v-btn
            :class="{ active: pixmode == 'qrcode' }"
            @click="changePix('qrcode')"
            >PIX com QRCode</v-btn
          >
          <v-btn
            :class="{ active: pixmode == 'copy' }"
            @click="changePix('copy')"
            >PIX copia e cola</v-btn
          >
        </v-layout> -->
        <v-container>
          <!-- <p class="fs-20 fw-300 text-center">PIX com QRCode</p> -->
          <v-layout flex-wrap>
            <v-flex pa-2 xs12 sm6 md4 class="text-center">
              <p class="value">
                <span>Valor: </span
                ><b
                  >R${{ formatterHelper.formatMoney(cart.totalValue + cart.taxValue) }}</b
                >
              </p>
              <p v-if="cart.creditCardParcelament > 1">
                <span>Parcela: </span
                ><b
                  >1 de {{cart.creditCardParcelament}}</b
                >
              </p>
              <img
                :src="'data:image/png;base64,' + qrCode.imagem_base64"
                alt=""
              />

              <div>
                <div class="qr-copy">
                  <InputText type="text" readonly="true" v-model="qrCode.emv" />
                  <v-btn @click="copy" color="bg-primary white--text" block>
                    <Icon name="fal fa-copy" size="16" class="mr-2" />
                    Copiar
                  </v-btn>
                </div>
                <p class="pa-1 green--text">
                  <b v-if="copyqr">Código copiado!</b>
                </p>
                <p class="text-center">Este código tem a expiração de 30 minutos</p>
              </div>
            </v-flex>
            <v-flex xs12 sm6 md8 pa-2>
              <v-layout pb-2 align-center>
                <img src="/images/pix1.png" alt="" />
                <b class="px-2 text-primary">1</b>
                <p>
                  Abra o App do seu banco ou instituição financeira e entre no 
                  <b>menu Pix</b>.
                </p>
              </v-layout>
              <v-layout pb-2 align-center>
                <img src="/images/pix2.png" alt="" />
                <b class="px-2 text-primary">2</b>
                <p>
                  Escolha a opção : <b>”Ler QR Code”</b> ou <b>”Pix Copia e Cola”</b> . Em alguns Apps essa opção está no submenu ”pagar”.
                </p>
              </v-layout>
              <v-layout pb-2 align-center>
                <img src="/images/pix3.png" alt="" />
                <b class="px-2 text-primary">3</b>
                <p>
                  <b>Escaneie o QR Code </b>ou <b>Cole o código</b>. Confirme as informações e <b>finalize</b>.
                </p>
              </v-layout>
              <v-layout pb-2 align-center>
                <img src="/images/pix4.png" alt="" />
                <b class="px-2 text-primary">4</b>
                <p>
                  Após efetuar o pagamento, você receberá um e-mail com a confirmação da sua doação, que poderá levar até dois minutos.
                </p>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout pb-2 align-center justify-end>
              <v-btn color="bg-primary white--text" @click="close"
                >Ja paguei</v-btn
              >
              </v-layout>
          <!-- <p class="fs-20 fw-300 text-center">PIX copia e cola</p>
          <v-layout flex-wrap>
            <v-flex pa-2 xs12 sm6 md4 class="text-center">
              <p class="value">
                <span>Valor: </span
                ><b
                  >R${{ formatterHelper.formatMoney(cart.totalValue, 0) }},00</b
                >
              </p>
              <div class="qr-copy">
                <InputText type="text" disabled="true" v-model="qrCode.emv" />
                <v-btn @click="copy" color="bg-primary white--text" block>
                  <Icon name="fal fa-copy" size="16" class="mr-2" />
                  Copiar
                </v-btn>
              </div>
              <p class="pa-1 green--text">
                <b v-if="copyqr">Código copiado!</b>
              </p>
            </v-flex>
            <v-flex xs12 sm6 md8 pa-2>
              <v-layout pb-2 align-center>
                <img src="/images/pix1.png" alt="" />
                <b class="px-2 text-primary">1</b>
                <p>
                  Abra o App do seu banco ou instituição financeira e entre no
                  <b>menu Pix</b>.
                </p>
              </v-layout>
              <v-layout pb-2 align-center>
                <img src="/images/pix2.png" alt="" />
                <b class="px-2 text-primary">2</b>
                <p>
                  Escolha a opção : ”<b>Pix Copia e Cola</b>”. Em alguns Apps
                  essa opção está no submenu ”pagar”.
                </p>
              </v-layout>
              <v-layout pb-2 align-center>
                <img src="/images/pix3.png" alt="" />
                <b class="px-2 text-primary">3</b>
                <p>
                  <b>Cole o código.</b> Confirme as informações e
                  <b>finalize</b>.
                </p>
              </v-layout>
              <v-layout pb-2 align-center>
                <img src="/images/pix4.png" alt="" />
                <b class="px-2 text-primary">4</b>
                <p>
                  Após efetuar o pagamento, você receberá um e-mail com a
                  confirmação da sua doação, que poderá levar até dois minutos.
                </p>
              </v-layout>
              <v-layout pb-2 align-center justify-end>
              <v-btn color="bg-primary white--text" @click="close"
                >Ja paguei</v-btn
              >
              </v-layout>
            </v-flex>
          </v-layout> -->
        </v-container>
      </v-container>
    </Modal>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CartService from "@/scripts/services/cart.service";
import QrCode from "@/scripts/models/qrCode.model";
import Cart from "@/scripts/models/cart.model";
export default {
  components: {
    Modal,
  },
  props: {
    withClose: false,
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      cartService: new CartService(),
      qrCode: new QrCode(),
      show: false,
      cartTotalPrice: 10,
      pixmode: "qrcode",
      copyqr: false,
      cart: new Cart(),
    };
  },
  methods: {
    open(cart) {
      this.cart = cart;
      this.show = true;
      this.loadQrCode();
    },
    close() {
      this.show = false;
      this.$emit("payed");
    },
    changePix(mode) {
      this.pixmode = mode;
    },
    copy() {
      var copyText = document.querySelector(".qr-copy input");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.copyqr = true;
      setTimeout(() => (this.copyqr = false), 3000);
    },
    loadQrCode() {
      this.cartService.getQrCodeActiveByCart(this.cart.id).then((data) => {
        this.qrCode = data;
      });
    },
  },
};
</script>