<template>
  <v-content>
    <ModalPix ref="modalPix" @payed="finishPix" />
    <v-container fluid fill-height>
      <v-layout align-center justify-center column>
        <FileImage
          :file="getLogo()"
          class="logo-campaign"
          :useBg="true"
          v-if="getLogo() != null"
        />
        <div class="logo-login" v-else></div>

        <div class="login-copyright">
          <v-layout w-100>
            <v-flex>
              Powered by
              <a
                href="https://www.abraceumacausa.com.br"
                target="_blank"
                class="ma-0"
                >abraceumacausa.com.br</a
              >
            </v-flex>
            <v-flex text-xs-right>
              <span>
                <a @click="showPolicyTerms = true">Política de Privacidade</a>
                <a @click="showServiceTerms = true">Termos de Uso</a>
              </span>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
    <FileImage
      :file="getBg()"
      :useBg="true"
      class="bg-campaign"
      v-if="getBg() != null"
    />
    <div class="login-bg" v-else></div>
    <UseTermsModal
      v-model="showServiceTerms"
      @close="showServiceTerms = false"
    ></UseTermsModal>
    <PrivacyPolicyTermsModal
      v-model="showPolicyTerms"
      @close="showPolicyTerms = false"
    ></PrivacyPolicyTermsModal>
  </v-content>
</template>
<script type="plain/text">
import ModalPix from "@/components/ModalPix.vue";
import Cart from "@/scripts/models/cart.model";
import CampaignService from "@/scripts/services/campaign.service";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
import UseTermsModal from "@/components/UseTermsModal.vue";
import PrivacyPolicyTermsModal from "@/components/PrivacyPolicyTermsModal.vue";
import CartService from "@/scripts/services/cart.service";
import { Routes } from "@/scripts/models/enums/routes.enum";

export default {
  props: ["person", "landingPageConfig"],
  components: {
    ModalPix,
    UseTermsModal,
    PrivacyPolicyTermsModal,
  },
  data() {
    return {
      cart: new Cart(),
      campaignService: new CampaignService(),
      landingPageConfig: new CampaignLandingPageConfig(),
      cartService: null,
      showServiceTerms: false,
      showPolicyTerms: false,
    };
  },
  created() {
    this.cartService = new CartService();
    this.campaignService.getCampaignLandingPageConfigBySubdomain().then(
      function (data) {
        this.landingPageConfig = data;
      }.bind(this)
    );
    this.$store.commit("HIDE_LOGIN");
  },
  mounted() {
    this.openModalPix();
  },
  methods: {
    openModalPix() {
      this.cartService.getCartById(this.$route.query.cartId).then(function(cart){
        this.$refs.modalPix.open(cart);
      }.bind(this))
    },
    finishPix() {
      this.redirectToCongratsPage();
    },
    getLogo() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.campaign != null &&
        this.landingPageConfig.campaign.file != null
      ) {
        return this.landingPageConfig.campaign.file;
      }
      return null;
    },
    getBg() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.heroFile != null
      ) {
        return this.landingPageConfig.heroFile;
      }
      return null;
    },
    redirectToCongratsPage() {
      this.$router.push({
        name: Routes.app.Congrats,
        query: { id: this.$route.query.cartId},
      });
    },
  },
};
</script>